<style lang="scss">
  .intro-title{
    position: relative;
    padding:0 30px;
  }

  .left-celebrate{
    position:absolute;
    left:10px;
    top:-40px;
  }
  .right-celebrate{
    position:absolute;
    right: -15px;
    top: -43px;
  }

  .po-password-strength.risky {
    color: #f95e68;
  }

  .po-password-strength.guessable {
    color: #fb964d;
  }

  .po-password-strength.weak {
    color: #fdd244;
  }

  .po-password-strength.safe {
    color: #b0dc53;
  }

  .po-password-strength.secure {
    color: #35cc62;
  }

  .google-btn{
    border: 1px solid #DFE3E8;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
    padding:14px 20px 0 20px;
    height: 48px;
    text-align:right;
    color: #757575;
    font-size: 14px;
    letter-spacing: 0.46px;
    line-height:1.2rem;
    cursor:pointer;

    @media (min-width: 360px) {
      font-size: 16px;
      text-align:center;
    }
  }

  .or{
    position: relative;
    font-size: 14px;
    color:#4A4A4A;
    z-index: 1;
    overflow: hidden;
    padding:20px 10px;
    text-align: center;
  }
  .or:before, .or:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: #EDEDED;
  }
  .or:before {
    margin-left: -50%;
    text-align: right;
  }
</style>
<template>
  <div class="max-w-xl mx-auto">
    <div class="text-center">
      <h1 class="mt-16 mb-8 text-2xl lg:text-3xl">
        Try Octoa free for 14 days
      </h1>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="w-full lg:w-1/2">
        <form
          class="max-w-md mx-auto bg-white shadow rounded px-8 pt-6 pb-8 mb-4"
          action="#"
          @submit.prevent="next"
        >
          <div
            class="google-btn"
            @click="loginWithGoogle"
          >
            <div class="absolute pin-l ml-4">
              <img
                src="@/assets/img/icons/ico-google.svg"
                width="20"
              >
            </div>
            Sign up with Google
          </div>
          <div class="or">
            &nbsp;&nbsp;or&nbsp;&nbsp;
          </div>
          <div class="input-group mb-4">
            <label
              class="block text-grey-darker text-sm mb-2"
              for="name"
            >
              First and last name
            </label>
            <input
              id="name"
              ref="name"
              v-model="form.name"
              v-validate="{ required: true }"
              name="name"
              class="w-full"
              type="text"
              placeholder=""
            >
            <span class="error">{{ errors.first('name') }}</span>
          </div>
          <div class="input-group mb-4">
            <label
              class="block text-grey-darker text-sm mb-2"
              for="email"
            >
              Email address
            </label>
            <input
              id="email"
              v-model.lazy="form.email"
              v-validate="{ required: true, email: true}"
              name="email"
              class="w-full"
              type="text"
              placeholder=""
            >
            <span class="error">{{ errors.first('email') }}</span>
          </div>
          <div class="input-group">
            <label
              class="block text-grey-darker text-sm mb-2"
              for="password"
            >
              Password <span class="text-grey-semi-light">(Include 8+ characters and at least one number)</span>
            </label>
            <input
              id="password"
              v-model="form.password"
              v-validate="{ required: true }"
              name="password"
              class="w-full"
              type="password"
              placeholder=""
              @input="checkPassword"
            >
            <password-meter
              :password="form.password"
              @score="onPasswordScore"
            />
            <div
              class="mt-1 po-password-strength"
              :class="passwordStrength"
            >
              {{ passwordScore }}
            </div>
            <span class="error">{{ errors.first('password ') }}</span>
          </div>
          <div class="mt-5 flex justify-end">
            <div v-if="!form.saving">
              <button
                class="green-btn rounded"
                type="submit"
              >
                <span class="px-4">Next</span>
              </button>
            </div>
            <div v-else>
              <img
                src="@/assets/img/icons/loader.svg"
                width="45"
              >
            </div>
          </div>
        </form>
        <div class="max-w-md leading-loosen mx-auto text-grey-darker mt-2">
          By clicking "Next" you agree to Octoa's <a
            class="text-grey-darker underline"
            href="https://octoa.com/terms-conditions/"
            target="_blank"
          >Terms of Service</a> and <a
            href="https://octoa.com/privacy-policy/"
            class="text-grey-darker underline"
            target="_blank"
          >Privacy Policy</a>.
        </div>
      </div>
      <div class="w-full lg:w-1/2 px-4 lg:px-16 pb-8 mt-12 lg:mt-0">
        <div class="flex items-center mb-5 md:mb-8">
          <div class="mr-2">
            <img src="@/assets/img/icons/ico-checkmark-purple.svg">
          </div>
          <div class="text-lg text-purple">
            No credit card required
          </div>
        </div>
        <div class="flex items-center mb-5 md:mb-8">
          <div class="mr-2">
            <img src="@/assets/img/icons/ico-checkmark-purple.svg">
          </div>
          <div class="text-lg text-purple">
            Trial ends automatically
          </div>
        </div>
        <div class="flex items-center mb-5 md:mb-8">
          <div class="mr-2">
            <img src="@/assets/img/icons/ico-checkmark-purple.svg">
          </div>
          <div class="text-lg text-purple">
            Cancel anytime
          </div>
        </div>
        <div class="flex items-center">
          <div class="mr-2">
            <img src="@/assets/img/icons/ico-checkmark-purple.svg">
          </div>
          <div class="text-lg text-purple">
            Need help? Come for a 1on1 setup
          </div>
        </div>

        <div class="flex mt-12">
          <div class="w-48 mr-4">
            <img src="@/assets/img/testimonals/aaron-daniel.png">
          </div>
          <div class="leading-normal">
            Your customer service always knows what I need before I even know what I need. Prompt, reliable, and saves me the organizational headaches every creative business goes through; Octoa has been truly priceless.
            <div class="mt-2 text-xs text-grey-darker">
              Aaron Daniel, Cinematographer
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import passwordMeter from 'vue-simple-password-meter'
import GoogleSignInButton from 'vue-google-signin-button-directive'

export default {
  name: 'CreateAccountDetails',
  components: { passwordMeter },
  directives: {
    GoogleSignInButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data(){
    return {
      clientId: '248391604423-fv62vlt999gmt16cu39dgt8oa63oi1j4.apps.googleusercontent.com',
      password_length: 0,
      contains_alphabet: false,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      passwordScore: null,
      passwordStrength: null,
    }
  },
  mounted() {
    this.$refs.name.focus()

    let emailPrefill = this.$route.query.email
    if(emailPrefill){
      this.form.email = emailPrefill
    }
  },
  methods: {
    loginWithGoogle() {
      this.$gAuth
      .signIn()
      .then(GoogleUser => {
        this.form.type = 'google'
        this.form.name = GoogleUser.getBasicProfile().getName()
        this.form.email = GoogleUser.getBasicProfile().getEmail()
        this.form.googleId = GoogleUser.getId()
        this.$emit('create')
        //console.log('getBasicProfile', GoogleUser.getBasicProfile())
        //console.log('getAuthResponse', GoogleUser.getAuthResponse())
      })
      .catch(error => {
        this.$toasted.global.general_error({
          message : 'Oops, there\'s an error connecting to your Google account.'
        })
      })
    },
    onPasswordScore({ score, strength }) {
      this.passwordStrength = strength
      if(score < 2){
        this.passwordScore = 'Weak'
      }
      if(score > 1 && score < 4){
        this.passwordScore = 'Average'
      }
      if(score == 4){
        this.passwordScore = 'Strong'
      }
    },
    checkPassword() {
      this.password_length = this.form.password.length
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.password_length > 7) {
        this.contains_eight_characters = true
      } else {
        this.contains_eight_characters = false
      }

      this.contains_alphabet = /[A-Za-z]/.test(this.form.password)
      this.contains_number = /\d/.test(this.form.password)
      this.contains_uppercase = /[A-Z]/.test(this.form.password)
      this.contains_special_character = format.test(this.form.password)

      if (this.contains_alphabet === true && this.contains_eight_characters === true && this.contains_number === true) {
        this.valid_password = true
      } else {
        this.valid_password = false
      }
    },
    async next(){

      this.$toasted.clear()

      if(!this.valid_password){
        this.$toasted.global.general_error({
          message : 'Please check if password contains 8+ characters and at least one number.'
        })
        return false
      }

      this.$validator.validate().then(result => {
        if(result){
          this.type = 'account'
          this.$emit('create')
         }
      })
    }
  },
}
</script>
